import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"
// import Tickets from "../components/tickets"

const IndexPage = () => (
	<Layout
		meta={{
			fullTitle: "Accento — Java/Web/Dev",
			description: "Accento is an amazing Java/Web/Dev conference in Karlsruhe, Germany.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<Snippet id="landing-accento" />
		<Snippet id="landing-history" />
		{/* <Tickets /> */}
		<Snippet id="landing-contact" />
	</Layout>
)

export default IndexPage
